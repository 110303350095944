import { getUser, companyUser } from "~/constants/apiRoute/auth"
import { getSettings } from "~/layers/company/services/useEntitySetting"
import type { IUserInfo } from "~/interfaces/iUserInfo"
import type { IEntity } from "~/interfaces/company/iEntity"
import type { IResponder } from "~/interfaces/iResponder"

export const useAuthStore = defineStore("auth", () => {
  const user = ref<IUserInfo | null>(null)
  const isLoggedIn = computed(() => !!user.value)
  const companyCookie = useCookie("company")
  const companies = ref<IEntity[]>([])
  const companySettings = ref([])

  async function fetchUser() {
    const response = await useApiFetch(`${getUser}`)
    if (response.success) {
      user.value = response?.data
      if (!companyCookie.value) companyCookie.value = response?.data?.entityId
    }
  }

  const getCompanies = async () => {
    const [response] = await Promise.all([
      (await useApiFetch(`${companyUser}`)) as IResponder,
    ])
    if (response.success) {
      companies.value = response?.data
    }
  }

  const getCompanySettings = async () => {
    const response: IResponder = await getSettings({
      company: companyCookie.value,
    })
    companySettings.value = response?.data
  }

  return {
    user,
    isLoggedIn,
    fetchUser,
    companies,
    getCompanies,
    companySettings,
    getCompanySettings,
  }
})
