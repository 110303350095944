import { useAuthStore } from "~/auth/stores/auth"
import { useNotification } from "~/stores/notification"
import Pusher from "pusher-js"
import type { INotification } from "~/interfaces/support/iNotification"

export default defineNuxtPlugin(() => {
  const auth = useAuthStore()
  const store = useNotification()
  const accessToken = useCookie("auth.token")
  const toast = useToaster()
  const { pusher, socketUrl, apiUrl, wsPort, wssPort } =
    useRuntimeConfig().public
  const messages = computed({
    get: () => store.messages,
    set: (val) => (store.messages = val),
  })
  const { status } = useAuth()
  if (status.value === "authenticated") {
    auth.fetchUser().then(() => {
      if (auth?.user?.id !== undefined) {
        if (accessToken.value) {
          const socket = new Pusher(pusher.key, {
            cluster: pusher.cluster,
            wsHost: socketUrl,
            wsPort: parseInt(wsPort),
            wssPort: parseInt(wssPort),
            forceTLS: false,
            disableStats: true,
            enabledTransports: ["ws", "wss"],
            channelAuthorization: {
              transport: "ajax",
              endpoint: `${apiUrl}/api/broadcasting/auth`,
              headers: {
                Authorization: `Bearer ${accessToken.value}`,
              },
            },
          })
          const channel = socket.subscribe(
            `private-notification.${auth?.user?.id}`,
          )
          channel.bind(
            "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
            (e: INotification) => {
              messages.value.push(e)
              toast.show({
                title: e.title,
                message: e.teaser,
                color: "success",
                closable: true,
                icon: "ph:bell-duotone",
              })
            },
          )
        }
      }
    })
  }
})
