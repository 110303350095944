import { settingOptions } from "~/constants/apiRoute/company"

export const getSettings = async (options: any = {}): Promise<IResponder> =>
  await useApiFetch(`${settingOptions}`, {
    query: options,
  })
export const postSetting = async (
  body: any,
  options: any
): Promise<IResponder> => await useSave(`${settingOptions}`, body, options)

export const showSetting = async (id: string): Promise<IResponder> =>
  await useApiFetch(`${settingOptions}/${id}`)

export const findSettingByName = async (
  name: string,
  company: string
): Promise<IResponder> =>
  await useApiFetch(`${settingOptions}/find-name`, {
    query: {
      name: name,
      company: company,
    },
  })
