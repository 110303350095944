import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAddonInputPassword, LazyAddonInputPhone, LazyBaseDatetimePicker, LazyBaseInputMask, LazyBaseInputMoney, LazyBaseMultiSelect, LazyBaseQuillTextarea, LazyButtonAddModal, LazyButtonBack, LazyButtonBatchDelete, LazyButtonCloseModal, LazyButtonDelete, LazyButtonFinalModal, LazyButtonModal, LazyButtonSubmit, LazyButtonUpdateModal, LazyButtonViewImage, LazyCompanyBar, LazyFlexTable, LazyFlexTableCell, LazyFlexTableHeading, LazyFlexTableRow, LazyFormAddress, LazyFormCard, LazyFormModal, LazyListGroupPages, LazyModalImage, LazyModal, LazyQuillTextArea, LazySelectAssetLocation, LazySelectAssetType, LazySelectBank, LazySelectBusinessPartner, LazySelectCity, LazySelectClient, LazySelectCompetency, LazySelectContract, LazySelectCountry, LazySelectCurrency, LazySelectDepartment, LazySelectDistrict, LazySelectEmployee, LazySelectEntity, LazySelectPermission, LazySelectPosition, LazySelectProjectFee, LazySelectProject, LazySelectProjectStatus, LazySelectProvince, LazySelectRole, LazySelectScope, LazySelectService, LazySelectSourceCollection, LazySelectSpeaker, LazySelectTemplateMilestone, LazySelectVendor, LazySelectVillage, LazySelect, LazyStatisticCardVertical, LazyTableCell, LazyTableHeading, LazyThemeToggle, LazyTimerNotification, LazyToolbarAccountMenu, LazyUApexChart, LazyUCheckBox, LazyUDatagrid, LazyUDatatable, LazyUDatepicker, LazyUFileInput, LazyUFlexTable, LazyUInput, LazyUInputMask, LazyUInputMoney, LazyUInputNumber, LazyUInputPassword, LazyUInputPhone, LazyUSelect, LazyUServerDatatable, LazyUSwitch, LazyUTable, LazyUTextarea, LazyUserNotification, LazyTairoLogo, LazyTairoLogoText, LazyTairoToaster, LazyTairoTocAnchor, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["AddonInputPassword", LazyAddonInputPassword],
["AddonInputPhone", LazyAddonInputPhone],
["BaseDatetimePicker", LazyBaseDatetimePicker],
["BaseInputMask", LazyBaseInputMask],
["BaseInputMoney", LazyBaseInputMoney],
["BaseMultiSelect", LazyBaseMultiSelect],
["BaseQuillTextarea", LazyBaseQuillTextarea],
["ButtonAddModal", LazyButtonAddModal],
["ButtonBack", LazyButtonBack],
["ButtonBatchDelete", LazyButtonBatchDelete],
["ButtonCloseModal", LazyButtonCloseModal],
["ButtonDelete", LazyButtonDelete],
["ButtonFinalModal", LazyButtonFinalModal],
["ButtonModal", LazyButtonModal],
["ButtonSubmit", LazyButtonSubmit],
["ButtonUpdateModal", LazyButtonUpdateModal],
["ButtonViewImage", LazyButtonViewImage],
["CompanyBar", LazyCompanyBar],
["FlexTable", LazyFlexTable],
["FlexTableCell", LazyFlexTableCell],
["FlexTableHeading", LazyFlexTableHeading],
["FlexTableRow", LazyFlexTableRow],
["FormAddress", LazyFormAddress],
["FormCard", LazyFormCard],
["FormModal", LazyFormModal],
["ListGroupPages", LazyListGroupPages],
["ModalImage", LazyModalImage],
["Modal", LazyModal],
["QuillTextArea", LazyQuillTextArea],
["SelectAssetLocation", LazySelectAssetLocation],
["SelectAssetType", LazySelectAssetType],
["SelectBank", LazySelectBank],
["SelectBusinessPartner", LazySelectBusinessPartner],
["SelectCity", LazySelectCity],
["SelectClient", LazySelectClient],
["SelectCompetency", LazySelectCompetency],
["SelectContract", LazySelectContract],
["SelectCountry", LazySelectCountry],
["SelectCurrency", LazySelectCurrency],
["SelectDepartment", LazySelectDepartment],
["SelectDistrict", LazySelectDistrict],
["SelectEmployee", LazySelectEmployee],
["SelectEntity", LazySelectEntity],
["SelectPermission", LazySelectPermission],
["SelectPosition", LazySelectPosition],
["SelectProjectFee", LazySelectProjectFee],
["SelectProject", LazySelectProject],
["SelectProjectStatus", LazySelectProjectStatus],
["SelectProvince", LazySelectProvince],
["SelectRole", LazySelectRole],
["SelectScope", LazySelectScope],
["SelectService", LazySelectService],
["SelectSourceCollection", LazySelectSourceCollection],
["SelectSpeaker", LazySelectSpeaker],
["SelectTemplateMilestone", LazySelectTemplateMilestone],
["SelectVendor", LazySelectVendor],
["SelectVillage", LazySelectVillage],
["Select", LazySelect],
["StatisticCardVertical", LazyStatisticCardVertical],
["TableCell", LazyTableCell],
["TableHeading", LazyTableHeading],
["ThemeToggle", LazyThemeToggle],
["TimerNotification", LazyTimerNotification],
["ToolbarAccountMenu", LazyToolbarAccountMenu],
["UApexChart", LazyUApexChart],
["UCheckBox", LazyUCheckBox],
["UDatagrid", LazyUDatagrid],
["UDatatable", LazyUDatatable],
["UDatepicker", LazyUDatepicker],
["UFileInput", LazyUFileInput],
["UFlexTable", LazyUFlexTable],
["UInput", LazyUInput],
["UInputMask", LazyUInputMask],
["UInputMoney", LazyUInputMoney],
["UInputNumber", LazyUInputNumber],
["UInputPassword", LazyUInputPassword],
["UInputPhone", LazyUInputPhone],
["USelect", LazyUSelect],
["UServerDatatable", LazyUServerDatatable],
["USwitch", LazyUSwitch],
["UTable", LazyUTable],
["UTextarea", LazyUTextarea],
["UserNotification", LazyUserNotification],
["TairoLogo", LazyTairoLogo],
["TairoLogoText", LazyTairoLogoText],
["TairoToaster", LazyTairoToaster],
["TairoTocAnchor", LazyTairoTocAnchor],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
